.div-gestion-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align:center;
  margin-bottom: 1vh;
}

.logo-top-gestion {
  width: 80px;
  margin: 20px;
}

li {
  margin: 0;
  padding: 0;
}

.blue {
  color: #0c86ef;
}

li::before {
  display: table-cell;
  text-align: right;
  padding-right: 0.3em;
}

.separation {
  padding-top: 150px;
}

.title-gestion {
  color: #0c86ef;
  text-align: center;
  padding-bottom: 3vh;
}

.first-p-gestion {
  padding-top: 25px;
  padding-bottom: 70px;
  max-width: 80%;
  margin: auto;
}

.below-gestion {
  height: 180px;
}

.contact-button {
  margin-left: 70%;
  text-decoration: none;
  padding: 6px;
  border-radius: 8px;
  background-color: #0c86ef;
  border-color: #0c86ef;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 800ms ease-out;
  transition: font-color 800ms ease-out;
}

.contact-button:hover {
  border: none;
  background-color: rgb(46, 116, 229);
}

@media only screen and (max-width: 900px) {
  .separation {
    padding-top: 50px;
  }


}

@media only screen and (max-width: 600px) {
  .contact-button {
    margin-left: 10px;
  }


}
