@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("./fonts/Nunito/Nunito.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("./fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: bold;
}

* {
  font-family: "Nunito";
  margin: 0;
  padding: 0;
}

html,body{
  overflow-x: hidden;
}
/*
.bc-all-parallax {
  top: 0;
  z-index: -2;
  background-image: url("./assets/bc3.jpg");
  background-size:cover;
  position: fixed;
  filter: blur(4px);
  height: 100%;
  width: 100%;
}

.bc-shade-parallax {
  padding-top: 0;
  top: 0;
  background-color: #81cfe0   ;
  z-index: -1;
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0.9;

}
*/

.pageUnderNavBar {
  padding-top: 40px;
}

body {
  background-color: aliceblue;
}

.page-content {
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
	max-width: 900px;

}

.separation-nav-content {
  margin-top: 110px;
}


@media only screen and (max-width: 900px) {
  .page-content {
		width: 90%;
	}
  .separation-nav-content {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 600px) { 
	.page-content {
		width: 95%;
	}
}
