.container {
  position:sticky;
  top:0;
  z-index: 10;
  min-height: 75px;
  display: flex;
  scroll-behavior: smooth;
  background: #0c86ef;
  font-family: "Helvetica Neue", sans-serif;
}

.logo_burger_navbar {
  padding: 8px;
  padding-left: 20px;
  display: block;
  max-width: max(30%, 230px);
  width: auto;
  height: auto;
}

.a_burger_menu {
  padding: 8px;
  position: absolute;
  right: 2%;
  font-size: 50px;
  color: rgb(240, 240, 245);
  text-decoration: none;
  text-transform: uppercase;
}

.popover {
  z-index: 100;
  display: none;
  box-shadow: 0px 6px 8px rgba(19, 19, 19, 0.7);
}
/* try to close menu on link same page
.popover .false{
  border-radius: 0 0 0 18%;
  padding: 8px;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  z-index: 1;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  background-color: #0c86ef;
  color: #fff;
  content: "×";
  cursor: pointer;
  opacity: 0;
  animation: 0.25s fadeIn ease forwards 0.15s;
}
*/
.popover:target {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 101vh;
  display: block;
  align-items: center;
  justify-content: center;
}

.popover .content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 0;
  height: 0;
  color: #fff;
  background-color: rgb(130, 175, 219);
  animation: 0.5s grow ease forwards;
}

.nav_list {
  list-style-type: none;
}

.nav_list a {
  text-decoration: none;
  font-size: 2em;
  color: #fff;
  overflow: hidden;
}




.nav_list a:hover {
  color: #0c86ef;
}

.button{
	text-decoration: none;
	color: white;
	font-weight: bold;
	font-size: 20px;
	position: relative;
	padding: 10px;
}


/*  */

a.button.one:before, a.button.one:after{
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	transition: all 0.6s ease;
}
a.button.one:before{
	top: -2.5%;
	left: -1%;
	border-top: 2px solid #0c86ef;
	border-left: 2px solid #0c86ef;	
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
a.button.one:after{
	bottom: -2.5%;
	right: -1%;
	border-bottom: 2px solid #0c86ef;
	border-right: 2px solid #0c86ef;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
a.button.one:hover:before, a.button.one:hover:after{
	width: 100%;
	height: 100%;
	transition: all 0.6s ease;
}


/* */
.nav_list a:target {
  font-size: 2em;
}

.nav_list_item {
  height: 100%;
  overflow: hidden;
}

.nav_list li {
  padding: 15px 0;
  text-transform: uppercase;
  transform: translateY(200px);
  opacity: 0;
  animation: 0.8s slideUp ease forwards 0.7s;
  position: relative;
}

.nav_list .li_2 {
  padding: 0;
  text-transform: none;
  transform: translateY(200px);
  opacity: 0;
  animation: 1.3s slideUp ease forwards 1s;
  position: relative;
}

.nav_list li::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 0px;
  left: 0;
  bottom: 10px;
  background: rgb(#0c86ef);
  transition: all 0.5s ease;
}

.nav_list li:hover:before {
  width: 100%;
}

.popover .nav_list_sub_item {
  padding-left: 30%;
  font-size: 1.3em;
  transform: translateY(200px);
  animation: 0.8s slideUp ease forwards 0.7s;
}

.popover .close::after {
  border-radius: 0 0 0 18%;
  padding: 8px;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  z-index: 1;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  background-color: #0c86ef;
  color: #fff;
  content: "×";
  cursor: pointer;
  opacity: 0;
  animation: 0.25s fadeIn ease forwards 0.15s;
}

@keyframes grow {
  100% {
    height: 100%;
    width: 100%;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}



@media only screen and (min-width: 910px) {
  .container {
    z-index: 2;
    display: none;
  }

  #navbar {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 70px;
    overflow: hidden;
    background-color: rgb(27, 96, 207);
  }

  #navbar a {
    float: left;
    display: flex;
    color: #f2f2f2;
    text-align: center;
    align-items: center;
    padding: 14px;
    text-decoration: none;
  }

  .content {
    padding: 16px;
  }

  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .sticky + .content {
    padding-top: 60px;
  }
}
