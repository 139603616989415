

.home-logo {
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.column-home {
  float: left;
  width: 50%;
  padding-top: 9em;
}

/* Clear floats after image containers */
.row-home::after {
  content: "";
  clear: both;
  display: table;
}



.title-welcome-home {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 7%;
  text-align: left;
  color: #0c86ef;
}
.title-welcome-home.f {
  font-size: 2.3em;
  text-align: center;
  padding-left: 0;
}

.image-home-page-top {
  margin-top: 2em;
  width: 100%;
}


.paragraph-home {
  padding-left: 5%;
  padding-right: 5%;
  font-size: larger;
  color: rgba(47, 123, 189, 0.9);
}

.paragraph-home::first-letter {
  color: #0c86ef;
  font-size: 130%;
}

/* CARDS */
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  margin: 10px;
  padding: 10px;
  width: 27%;
  min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.card__link,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__icon {
  font-size: 30px;
}

.card__title {
  grid-row: 2/4;
  padding-top: 35px;
  font-size: 1em;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: radial-gradient(#3fbafe, #0c86ef);
}

.card.pl {
  background-color: none;
  box-shadow: none;
}

@media only screen and (max-width: 950px) {

  .column-home {
    float: left;
    width: 50%;
    padding-top: 3em;
  }
  .title-welcome-home {
    padding-left: 1%;
  }

  .card {
    margin: 2%;
  }

}

@media only screen and (max-width: 600px) {
  .card {
    width: 45%;
    padding: 2%;
  }
  .card.pl {
    display: none;
  }

}
