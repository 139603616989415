.button-map-list {
  padding: 0;
  border-color: #0c86ef;
  width: 55px;
  background-color: #0c86ef;
  color: white;
  font-size: 25px;
}

.button-map-list:hover {
  cursor: pointer;
}
.button-map-list.one {
  margin-left: 30%;
  border-radius: 7px 0px 0px 7px;
}

.button-map-list.two {
  border-radius: 0px 7px 7px 0px;
}

.button-map-list.one:focus {
  background: white;
  color: #0c86ef;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 5px #0c86ef;
     -moz-box-shadow: inset 0px 0px 5px #0c86ef;
          box-shadow: inset 0px 0px 5px #0c86ef;
}

.button-map-list.two:focus {
  background: white;
  color: #0c86ef;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 5px #0c86ef;
     -moz-box-shadow: inset 0px 0px 5px #0c86ef;
          box-shadow: inset 0px 0px 5px #0c86ef;
}





.label_contact_form::after {
  content: attr(data-end);
  color: red;
}

.divFormContact > span {
  margin: 0 5px 0 15px;
}

.divFormContact {
  z-index: -1;
  margin-left: 10%;
  font-family: "Montserrat", sans-serif;
  content: "Hi";
  position: relative;
  background: #50505a;
  color: #fff;
  width: 330px;
  padding: 16px 4px 16px 0;
  border-radius: 6px;
  font-size: 13px;
  box-shadow: 10px 10px 40px -14px #000;
  margin-bottom: 40px;
}

.title_contact_page {      
  padding-left: 15%;
  padding-right: 17%;
  z-index: 5;
  color: #fff;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 45%;
  font-size: 36pt;
  text-shadow: 0px 3px;
}

.map_contact {
  overflow: hidden;
  clip-path: polygon(0 10%, 100% 10%, 100% 100%, 0% 100%);
}

.iframe_map_contact {
  border: none;
  display: block;
  margin: 0 auto;
  max-width: 800px;
  border-radius: 15px;
  margin-bottom: 14vh;
}

/* contact form */
.form_contact {

  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vh;
  padding-bottom: 10vh;
  display: flex;
  flex-wrap: wrap;
}

.break_contact_form {
  flex-basis: 100%;
}

.group_label_input_contact_form_first {
  width: 90%;
  margin-left: 10%;
  margin-bottom: 25px;
}

.group_label_input_contact_form_first > .select {
  width: 89%;
  height: 65%;
}

.group_label_input_contact_form_0 {
  width: 45%;
  margin-left: 10%;
  margin-bottom: 25px;
}

.group_label_input_contact_form_0 > input {
  width: 80%;
  height: 65%;
  border-radius: 7px;
  padding: 2px;
  padding-left: 5px;
  border: 1px solid rgb(27, 96, 207);
}

.group_label_input_contact_form_1 {
  width: 44%;
  margin-bottom: 25px;
}

.group_label_input_contact_form_1 > input {
  width: 80%;
  height: 65%;
  border-radius: 7px;
  padding: 2px;
  padding-left: 5px;
  border: 1px solid rgb(27, 96, 207);
}

.group_label_input_contact_form_2 {
  width: 55%;
  margin-left: 10%;
  margin-bottom: 25px;
}

.group_label_input_contact_form_2 > input {
  width: 100%;
  height: 65%;
  border-radius: 7px;
  padding: 2px;
  padding-left: 5px;
  border: 1px solid rgb(27, 96, 207);
}

.group_label_input_contact_form_3 {
  width: 25%;
  margin-left: 5%;
  margin-bottom: 25px;
}

.group_label_input_contact_form_3 > input {
  width: 80%;
  height: 65%;
  border-radius: 7px;
  padding: 2px;
  padding-left: 5px;
  border: 1px solid rgb(27, 96, 207);
}

.group_label_input_contact_form_4 {
  width: 55%;
  margin-left: 10%;
  margin-bottom: 25px;
}

.group_label_input_contact_form_4 > input {
  width: 90%;
  height: 65%;
  border-radius: 7px;
  padding: 2px;
  padding-left: 5px;
  border: 1px solid rgb(27, 96, 207);
}

.group_label_input_contact_form_5 {
  width: 25%;
  margin-bottom: 25px;
}

.group_label_input_contact_form_5 > input {
  width: 100%;
  height: 65%;
  border-radius: 7px;
  padding: 2px;
  padding-left: 5px;
  border: 1px solid rgb(27, 96, 207);
}

.group_label_input_contact_form_6 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.group_label_input_contact_form_6 > textarea {
  width: 100%;
  height: 100px;
  max-width: 100%;
  max-height: 500px;
  min-width: 100%;
  min-height: 100px;
  border-radius: 7px;
  padding-left: 6px;
  padding-top: 5px;
}

.group_label_input_contact_form_7 {
  font-size: smaller;
  margin-left: 10%;
  display: flex;
  max-width: 80%;
}

.group_label_input_contact_form_7 > label {
  padding-top: 3px;
  padding-left: 10px;
}

.group_label_input_contact_form_7 > input {
  margin-top: 5px;
  height: 15px;
}

.submit_contact_form {
  margin-left: 35%;
  width: 30%;
  height: 40px;
  border-radius: 8px;
  background-color: rgb(27, 96, 207);
  border-color: rgb(27, 96, 207);
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 800ms ease-out;
  transition: font-color 800ms ease-out;
}

.submit_contact_form:hover {
  border: none;
  background-color: rgb(46, 116, 229);
}

.group_label_input_contact_form_0 > input:focus {
  box-shadow: 0px 0px 10px 2px rgba(141, 156, 244, 0.75);
}
.group_label_input_contact_form_1 > input:focus {
  box-shadow: 0px 0px 10px 2px rgba(141, 156, 244, 0.75);
}
.group_label_input_contact_form_2 > input:focus {
  box-shadow: 0px 0px 10px 2px rgba(141, 156, 244, 0.75);
}
.group_label_input_contact_form_3 > input:focus {
  box-shadow: 0px 0px 10px 2px rgba(141, 156, 244, 0.75);
}
.group_label_input_contact_form_4 > input:focus {
  box-shadow: 0px 0px 10px 2px rgba(141, 156, 244, 0.75);
}
.group_label_input_contact_form_5 > input:focus {
  box-shadow: 0px 0px 10px 2px rgba(141, 156, 244, 0.75);
}
.group_label_input_contact_form_6 > textarea:focus {
  box-shadow: 0px 0px 10px 2px rgba(141, 156, 244, 0.75);
}

@media only screen and (max-width: 900px) {
  .line-1 {
    animation: none !important;
    white-space: normal; 
  }

  .line-2 {
    animation: none !important;
    white-space: normal; 
  }
}

@media only screen and (max-width: 600px) {
  .form_contact {
    width: 100%;
  }

  .group_label_input_contact_form_0 {
    margin-left: 5%;
    width: 50%;
  }

  .group_label_input_contact_form_1 > input {
    width: 90%;
    margin-left: -5%;
  }

  .group_label_input_contact_form_2 {
    margin-left: 5%;
    width: 50%;
  }

  .group_label_input_contact_form_3 {
    margin-left: 5%;
    width: 32%;
  }
  .group_label_input_contact_form_3 > input {
    width: 100%;
  }

  .group_label_input_contact_form_4 {
    margin-left: 5%;
    width: 60%;
  }

  .group_label_input_contact_form_5 {
    margin-left: -3px;
    width: 27%;
  }

  
  .group_label_input_contact_form_6 {
    width: 90%;
    margin-left: 3.8%;
  }
  

}