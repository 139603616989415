$base-spacing-unit: 24px;
$half-spacing-unit: 12px;


$color-form-highlight: #EEEEEE;

*, *:before, *:after {
	box-sizing:border-box;
}


h1,h2,h3,h4,h5,h6 {
	margin:0;
}

.container-contact {
	margin-top: 30px;
	margin-bottom: 30px;
	max-width: 1200px;
	margin-right:auto;
	margin-left:auto;
	display:flex;
	justify-content:center;
	align-items:center;
	min-height:100vh;
}

.table-contact {
	width:100%;
	border:1px solid $color-form-highlight;
}

.table-header-contact {
	display:flex;
	width:100%;
	background:#0c86ef;
	padding: 30px 0;
}

.table-row-contact {
	display:flex;
	width:100%;
	padding:($half-spacing-unit * 1.5) 0;
	
	&:nth-of-type(odd) {
		background:$color-form-highlight;
	}
}

.table-data-contact, .header__item-contact {
	flex: 1 1 20%;
	text-align:center;
}

.header__item-contact {
	text-transform:uppercase;
}
.filter__link {
	color:white;
	text-decoration: none;
	position:relative;
	display:inline-block;
	padding-left:$base-spacing-unit;
	padding-right:$base-spacing-unit;
	
	&::after {
		content:'';
		position:absolute;
		right:-($half-spacing-unit * 1.5);
		color:white;
		font-size:$half-spacing-unit;
		top: 50%;
		transform: translateY(-50%);
	}
	
	
}