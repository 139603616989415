.card-lien {
    position: relative;
    border-radius: 15px;
    background-color: rgb(240, 245, 250);
    width: 15em;
    height: 12em;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;    transition: 0.7s;
    margin-bottom: 20px;
}

.card-lien:hover{
    cursor: pointer;
    background-color: rgb(221, 228, 235);
}

.image-card-lien {
    max-width: 85%;
    max-height: 85%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}