.title-aide-top {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 19%;
  width: 100%;
  font-size: 2.4em;
  color: #fff;
  box-shadow: 10px 10px 40px -14px #000;
  text-shadow: 0px 1px black;
}

.title-aide-top-phone {
    visibility: hidden;
    color: #0c86ef;
    text-align: center;
}

.image-aides-page-top {
  overflow: hidden;
  width: 100%;
  max-width: 1500px;
  box-shadow: 5px 5px 5px rgb(169, 169, 201);
}

.img_gradient_aides_page {
  position: relative;
  display: block;
  margin-right: auto;
}

.img_gradient_aides_page::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 1500px;
  height: 99%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 700px) {

    .title-aide-top {
        visibility: hidden;
    }

    .title-aide-top-phone {
        visibility: visible;
        padding-top: 2em;
    }

    .img_gradient_aides_page::after {
      background: none;
    }

}


@media only screen and (min-width: 1500px) {
    .img_gradient_aides_page{
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 1500px;
    }
}

