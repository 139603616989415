.ressource-title {
    color: #0c86ef;
}

.ressource-cards {
    margin-top: 2em;
    margin-bottom: 4em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2%;
    width: 100%;
    height: 100%;
}