$dark-blue: #0C76F0
$light-green: #B2D9A6
$yellow: #FFD433
$gray: #e1e4eb
$light-blue: #0c86ef

.link-card-stacked
  text-decoration: none
  color: #fff

.card__link,
.card__icon 
  position: relative
  text-decoration: none
  color: rgba(255, 255, 255, 0.9)


.card__link::after
  position: absolute
  top: 25px
  left: 0
  content: ""
  width: 0%
  height: 3px
  background-color: rgba(255, 255, 255, 0.6)
  transition: all 0.5s


.card__link:hover::after
  width: 100%


.deck
  position: relative
  
  max-width: 500px
  width: 100%
  margin: 5em auto 3em
  @media (max-width: 705px)
    margin: 4em auto
  @media (hover: none)
    margin: 4em auto
  &:before
    content: ''
    position: absolute
     
    width: 120%
    height: 95%
    top: -1.5em
    left: 0em
    border-radius: 8px
    @media (max-width: 550px)
      left: 1.5em
.card-stacked
  position: relative
  background-color: lighten($dark-blue, 5%)
  padding: 2em
  z-index: 5
  border-radius: 8px
  box-shadow: 4px 4px 20px rgba(0,0,0,.3)
  transition: 200ms ease-in-out
  + .card-stacked
    margin-top: 1em
  &:hover
    z-index: 7
    transform: scale(1.05)
    box-shadow: 0 4px 25px rgba(0,0,0,.4)
  &:first-of-type
    margin-left: -3em
    margin-right: 3em
    @media (max-width: 705px)
      margin-left: -1em
      margin-right: 1em
    @media (hover: none)
      margin-left: -1em
      margin-right: 1em
    @media (max-width: 550px)
      margin: 0
  &:nth-of-type(2)
    margin-left: 5em
    margin-right: -5em
    margin-top: -2em
    @media (max-width: 705px)
      margin-left: 1em
      margin-right: -1em
      margin-top: 1em
    @media (hover: none)
      margin-left: 1em
      margin-right: -1em
      margin-top: 1em
    @media (max-width: 550px)
      margin: 1em 0 0
  &:nth-of-type(3)
    margin-top: -2em
    margin-left: -1em
    margin-right: 1em
    @media (max-width: 705px)
      margin-left: -1em
      margin-right: 1em
      margin-top: 1em
    @media (hover: none)
      margin-left: -1em
      margin-right: 1em
      margin-top: 1em
    @media (max-width: 550px)
      margin: 1em 0 0
  &:nth-of-type(4)
    margin-left: 2em
    margin-right: -2em
    margin-top: -2em
    @media (max-width: 705px)
      margin-left: 0em
      margin-right: 0em
      margin-top: 1em
    @media (hover: none)
      margin-left: -1em
      margin-right: 1em
    @media (max-width: 550px)
      margin: 0
      &:after
        content: ''
        position: absolute
        left: 0
        right: 0
        bottom: -.7em
        display: block
        border-bottom: 5px solid $dark-blue
    @media (max-width: 550px)
      margin: 1em 0 0
.cardHeader
  grid-template-columns: 36px auto 100px
  align-items: center
  text-transform: uppercase
  .cardHeader_account
    margin: 30px
    color: #fff
    font-size: .95em
    font-weight: 600
    letter-spacing: 1px
.cardBody
  display: flex
  margin: 0 10px 10px
  margin-top: 17px
  .cardText
    margin: 0 10px 0 0
    white-space: pre-line
    color: $gray
    font-weight: 400
    line-height: 1.5
